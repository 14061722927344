<script setup>
import FormMailTpl from "@/blocks/FormMailTpl.vue";
import CarsSlider from "@/blocks/CarsSlider.vue";
import CarsList from "@/blocks/CarsList.vue";
import CarSearch from "@/blocks/CarSearch.vue";
import Modal from "@/components/Modal.vue";
import { reactive } from "vue";
import RentForm from "@/components/forms/RentForm.vue";
import BuyForm from "@/components/forms/BuyForm.vue";

const container = document.getElementById("car_search");

const dataParams = container.dataset;

const modals = reactive({
  selectedCar: null,
  showBuy: false,
  showRent: false,
  showFeedback: false,
});

const openRent = (car) => {
  modals.selectedCar = car;
  modals.showRent = true;
};

const openBuy = (car) => {
  modals.selectedCar = car;
  modals.showBuy = true;
};

const closeModal = () => {
  modals.showBuy = false;
  modals.showRent = false;
  modals.showFeedback = false;
};
</script>

<template>
  <FormMailTpl @formSended="modals.showFeedback = true" />
  <CarsSlider />
  <CarsList
    v-bind:cars="JSON.parse(dataParams.cars)"
    @openBuy="openBuy"
    @openRent="openRent"
  />
  <CarSearch v-bind="dataParams" @openBuy="openBuy" @openRent="openRent" />
  <Modal @close="closeModal">
    <template v-if="modals.showFeedback">
      <div class="rent-form--sended">
        <p class="rent-form__txt">
          Ваша заявка отправлена. В ближайшее время мы вам перезвоним
        </p>
        <button @click="closeModal" class="btn_with_corner">ок</button>
      </div>
    </template>
    <RentForm
      v-if="modals.showRent"
      @close="closeModal"
      :carPrice="modals.selectedCar.price"
      :carName="modals.selectedCar.title"
    />
    <BuyForm
      v-if="modals.showBuy"
      @close="closeModal"
      :carName="modals.selectedCar.title"
    />
  </Modal>
</template>

<style></style>
