import { createApp } from "vue";
import "@/style.scss";
import App from "./App.vue";
import VueTheMask from "vue-the-mask";
import { setupCalendar, DatePicker } from "v-calendar";
import "v-calendar/style.css";

import { register } from "swiper/element/bundle";

const app = createApp(App);
app.use(VueTheMask).use(setupCalendar, {});

app.component("VDatePicker", DatePicker);

app.mount("#app");

register();
